<template>
  <div>
    <div> <p  class="category">访问记录</p>
      <el-button size="mini" @click="goTo(0)" class="category add">新增</el-button></div>
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="国家">
            <el-select v-model="form.country" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
              v-model="form.datetime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        </el-form>
    </div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="ip"
          width="180"
          >
        <template slot-scope="scope">
          <span style="margin-left: 10px" >[{{ scope.row.country}}]{{scope.row.ip}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问地址"
          >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.url}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="来源"
          >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.referrer}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="访问时间"
          width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.create_time}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
        >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "AccessView",
  data() {
    return {
      tableData: [],
      id: '',
      total: 100,
      form:{
        datetime:'',
        country:''
      },
      options:[

        {'value':'中国',
            'label': '中国'
        }
          ,
        {
          'value':'美国',
          'label':'美国'
        }
      ]
    }
  },
  components:{
  },
  created() {
    this.getCate();
    this.getCountry();
  },
  methods: {
    getCountry(){
      axios.get('/api/Access/getCountry.html').then((res)=>{
        if(res.data.code == 200){
          this.options = res.data.data;
        }
      });
    },
    getCate(page = 1){
      var str = '';
      var country = this.form.country;
      const time = this.form.datetime;
      if(country) str = str + '&country='+country
      if(time)  str = str+'&startime='+time[0]+'&endtime='+time[1];
      axios.get('/api/Access/index.html?page='+page+str).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    },
    onSubmit() {
      this.getCate()
    }
  }
};
</script>
<style scoped lang=''>
</style>