<template>
  <div class="thisbody">
  <div id="login" class='login' style="">
    <div class="logintop">
      <p class="p1">管理员登录</p>
      <p class="p2">请输入用户名密码登录系统</p>
    </div>
    <div>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-input v-model="ruleForm.username" placeholder="用户名" ></el-input>
        <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="密码"></el-input>
        <div>
          <el-input v-model="ruleForm.captcha" placeholder="验证码" class="yzm" ></el-input>
          <div  class=" yzmimg">
            <img :src='this.src' alt="captcha"  @click="getnewimg()" />
          </div>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <!--        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      ruleForm: {
        username: '',
        password: '',
        captcha: '',

      },
      src: "/api/Index/verify.html",
      rules: {
        username: [
          { required: true,message:'用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true,message:'密码不能为空', trigger: 'blur' },
          { min:6,message:'密码不少于6位', trigger: 'blur' }
        ],
        captcha: [
          { required: true,message:'验证码不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    getnewimg(){
      const src = '/api/Index/verify.html?rand='+Math.random();
      this.src = src;
    },
    submitForm(event) {
      // event.preventDefault();
      let formdata = new FormData();
      formdata.append('username',this.ruleForm.username);
      formdata.append('password',this.ruleForm.password);
      formdata.append('captcha',this.ruleForm.captcha);
      this.$refs[event].validate(() => {
          this.axios({
            method:'post',
            url:'/api/Login/setLogin.html',
            data:formdata
          }).then((res)=>{
            // console.log(res.data);
            if(res.data.code != 200) {
              const src = '/api/Index/verify.html?rand='+Math.random();
              this.src = src;
              alert(res.data.msg);
            }
            else{
              window.sessionStorage.setItem('token',res.data.id);
              // localStorage.setItem('token','');
              if(res.data.id == 1){
                this.$router.push({
                  name:'home'
                })
              }else{
                this.$router.push({
                  name:'homes'
                })
              }

            }
          })
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}

</script>

<style>
  body{
    background: url("http://demo.wigordigital.com/static/images/login.jpg") no-repeat center 0;
    background-color: #0069BE;
  }
  .thisbody{
    position: relative;width: 568px;height: 418px; top: 200px;left:200px;background: rgba(255,255,255,0.3);border-radius: 8px;
  }
  .thisbody label{
    color: #FFFFFF;
  }
  .login{
    position:relative;margin: 4px;top:4px;color:#FFFFFF;width: 560px;height: 410px;background: rgba(255,255,255,0.2);border-radius: 8px;
  }
  .el-form-item{
    margin: 30px auto;
  }
  .thisbody button{
    width: 476px;margin: 5px 41px 10px 41px;background-color: orange;
  }
  .el-form-item{
    margin: 20px auto;
  }
  .thisbody input{
    width: 476px;margin: 5px 41px 10px 41px;
  }
  .thisbody .yzm input{
    width: 230px;margin: 5px 0px 10px 41px;
  }
  .thisbody .yzm{
    display: inline-block;width: 280px;
  }
  .thisbody .yzmimg{
    display: inline-block;width: 230px; vertical-align: middle;margin-left: 27px;
  }
  .logintop{
    height: 140px;width: 100%;background: url("http://demo.wigordigital.com/suo.png") no-repeat 480px 61px;
  }
  .logintop .p1{
    padding:31px 0px 0px 0px;
    width: 328px;margin-left:41px;
    font-size: 30px;letter-spacing:5px;
  }
  .logintop .p2{
    padding:0px 0px 0px 0px;
    width: 328px;margin-left:41px;line-height: 30px;letter-spacing:2px;
  }
</style>