<template>
  <el-container>
    <el-header>
      <div @click="outlogin" class="menu-left">Wigor网站管理系统V1.0</div>
      <el-button @click="outlogin" class="menu-right">退出</el-button>
    </el-header>
    <el-container>
      <!-- <el-aside>Aside</el-aside> -->
      <AsidesView></AsidesView>
      <el-main>
        <router-view>这是首页</router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AsidesView from "../components/AsidesView.vue";
import axios from "axios";
export default {
  name: "HomesView",
  data(){
    return{
      lang:'_cn',
    }
  },
  components: { AsidesView },
  methods:{
    getUserList(){
      axios.get('/api/Home/getLang.html').then((res)=>{
        // console.log(res.data)
        this.lang = res.data.data
      })
    },
    outlogin(){
      axios.get('/api/Login/outlogin.html').then((res)=>{
        if(res.data.code == 200) {
          window.sessionStorage.removeItem('token');
          this.$router.push({
            name:'login'
          })
        }
      })
    },
    changLang(val){
      axios.get('/api/Home/changeLang.html?lang='+val).then((res)=>{
        // console.log(res.data)
        if(res.data.code == 200) location.reload()
      })
    }
  },
  mounted() {
    this.getUserList();
  }
};
</script>
<style>
.menu-left{
  float: left;
}
.menu-right{
  float: right;
}
.el-header button{
  margin: 10px 0 10px 10px;
}
.el-header {
  /* background-color: #b3c0d1; */
  background-color: #373f41;
  /* color: #333; */
  color: #fff;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  /* background-color: #d3dce6; */
  color: #333;
  text-align: center;
  /* line-height: 200px; */
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center;
  line-height: 160px; */
}
.category{
  display: inline-block;
}
.add{
  float: right;
}
</style>
