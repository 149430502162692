<template>
  <el-aside :width="isCollapse ? '64px' : '200px'">
    <div class="aside">
      <div class="toggle-button" @click="toggle">|||</div>
      <el-menu
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        router
        :unique-opened="true"
        background-color="#373f41"
        text-color="#fff"
        :collapse-transition="false"
        :default-active="activePath"
      >
        <el-menu-item index="/ins" @click="saveState('/ins')">
          <template slot="title">
            <i class="el-icon-s-goods"></i>
            <span slot="title">产品质量</span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </el-aside>
</template>
  <script>
export default {
  name: "AsidesView",
  data() {
    return {
      activePath: "",
      isCollapse: false,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    toggle() {
      this.isCollapse = !this.isCollapse;
    },
    saveState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
  created() {
    this.activePath = window.sessionStorage.getItem("activePath");
  },
};
</script>
  <style scoped lang='scss'>
.toggle-button {
  background-color: #4d575a;
  font-size: 10px;
  line-height: 26px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
// ::v-deep.el-submenu__title:focus,.el-submenu__title:hover {
//     // background-color: #416895;
//     // outline: 0;
//     // background-color: #ecf5ff;
// }
::v-deep .el-submenu__title {
  text-align: left;
  background-color: #373f41;
}
.el-menu-item {
  text-align: left;
}
.el-menu {
  border-right: none;
  background-color: #373f41;
}
// .el-radio-group {
//   width: 115px;
// }
.aside {
  height: calc(100vh - 60px);
  color: #fff;
}
aside{
  background-color: #373f41;
}
.aside,
  .el-submenu,
  // .el-menu-item-group,
  .el-menu-item {
  background-color: #373f41;
  color: #fff;
  span {
    color: #fff;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  color: #fff;
  background-color: #373f41;
  width: 200px;
  min-height: 400px;
}
</style>