<template>
  <div>
    <div> <p  class="category">证书管理</p>
      <el-button size="mini" @click="goTo(0)" class="category add">新增</el-button></div>
    <el-table
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="ID"
          width="180">
        <template slot-scope="scope">
          <span style="" >{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="客户名称"
      >
        <template slot-scope="scope">
          <span style="" v-html="scope.row.customer"></span>
        </template>
      </el-table-column>
      <el-table-column
          label="查看证书"
          width="180">
        <template slot-scope="scope">
          <a @click="goToUrl(scope.row.id)" target="_blank"> <span style="color:blue;text-underline-color: blue;text-decoration: underline">查看证书</span></a>
        </template>
      </el-table-column>
      <el-table-column
          label="添加时间"
          width="180">
        <template slot-scope="scope">
          <span style="">{{ scope.row.update_time}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button size="mini" @click="goToPro(scope.row.id)">型号</el-button>
          <el-button size="mini" @click="goTo(scope.row.id)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="deleteContent(scope.$index,scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
        layout="prev, pager, next"
        :page-size=10
        @current-change="getCate"
        :page-count="total"
    >
    </el-pagination>

  </div>
</template>
<script>
import axios from "axios";
// import Dialog from "@/views/pages/getContentView.vue"


export default {
  name: "InsView",
  data() {
    return {
      tableData: [],
      id: '',
      total: 100,
    }
  },
  components:{
  },
  created() {
    this.getCate();
  },
  methods: {
    goTo(id){
      this.$router.push('/getins?id='+id)
    },
    goToPro(id){
      this.$router.push('/insinfo?id='+id)
    },
    goToUrl(id){
      window.open('https://ic.zjsancti.com/?id='+id,'_blank');
    },
    getCate(page = 1){
      axios.get('/api/Ins/index.html?page='+page).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.data;
          this.total = res.data.total
        }
      });
    },
    deleteContent(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Ins/deleteins.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style scoped lang=''>
</style>