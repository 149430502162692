<template>
  <el-form ref="form" :inline="true"  :model="form" label-width="120px">
    <div >
      <p>基本信息</p>
<!--      <el-form-item label="公司名称" class="thisitem">-->
<!--        <el-input v-model="form.company"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="英文名称">-->
<!--        <el-input v-model="form.company_en"></el-input>-->
<!--      </el-form-item>-->
      <!--    <el-form-item label="右上">-->
      <!--      <el-input v-model="form.righttop" placeholder=""></el-input>-->
      <!--    </el-form-item>-->
      <el-form-item label="客户">
        <el-input v-model="form.customer"></el-input>
      </el-form-item>
      <el-form-item label="合同号">
        <el-input v-model="form.contract"></el-input>
      </el-form-item>
      <el-form-item label="编号">
        <el-input v-model="form.nubmer"></el-input>
      </el-form-item>
      <el-form-item label="标准">
        <el-input v-model="form.standard"></el-input>
      </el-form-item>
      <!--    <el-form-item label="检验人">-->
      <!--      <el-input v-model="form.test_name"></el-input>-->
      <!--    </el-form-item>-->
<!--      <el-form-item label="网址">-->
<!--        <el-input v-model="form.web"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="邮箱">-->
<!--        <el-input v-model="form.email"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="检验时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.test_date"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <!--    <el-form-item label="负责人">-->
      <!--      <el-input v-model="form.quality_name"></el-input>-->
      <!--    </el-form-item>-->
      <el-form-item label="负责时间">
        <el-date-picker
            value-format="yyyy:MM:dd HH:mm:ss"
            v-model="form.quality_date"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
    </div>
    <div>
      <p>产品其它检验</p>


      <el-form-item label="表面质量">
        <el-input v-model="form.other_report"></el-input>
      </el-form-item>
      <el-form-item label="外观尺寸">
        <el-input v-model="form.other_exter"></el-input>
      </el-form-item>
      <el-form-item label="晶间腐蚀-依据">
        <el-input v-model="form.other_intspec"></el-input>
      </el-form-item>
      <el-form-item label="晶间腐蚀-结果">
        <el-input v-model="form.other_intspecr"></el-input>
      </el-form-item>
      <el-form-item label="涡流检测-依据">
        <el-input v-model="form.other_eddspec"></el-input>
      </el-form-item>
      <el-form-item label="涡流检测-结果">
        <el-input v-model="form.other_eddspecr"></el-input>
      </el-form-item>
      <el-form-item label="晶粒度试验-依据">
        <el-input v-model="form.other_graspec"></el-input>
      </el-form-item>
      <el-form-item label="晶粒度试验-结果">
        <el-input v-model="form.other_graspecr"></el-input>
      </el-form-item>
      <el-form-item label="X-射线-依据">
        <el-input v-model="form.other_xspec"></el-input>
      </el-form-item>
      <el-form-item label="X-射线-结果">
        <el-input v-model="form.other_xspecr"></el-input>
      </el-form-item>
    </div>

    <el-form-item >
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <!--      <el-button>取消</el-button>-->
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "InsView",
  data() {
    return {
      form: {}
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      let geturl = window.location.href;
      let geturlinfo = geturl.split('?')[1];
      let getqys = new URLSearchParams('?'+geturlinfo);
      let id = getqys.get('id');
      axios.get('/api/Ins/getIns.html?id='+id).then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }

      });
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.logo = array[0]['file_url'];
    },
    handleChange(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onSubmit() {
      this.axios({
        method:'post',
        url:'/api/Ins/setIns.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
        }
      })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
.thisitem{display: block}
</style>